import Vue from "vue";
import Antd from "ant-design-vue";
import { message } from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

import axios from "axios";
import dayjs from "dayjs";
import VueClipboard from 'vue-clipboard2'
import Icon from 'vue-awesome/components/Icon'

import store from "./store";
import VueCookies from "vue-cookies";

import App from "./App";
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import NoHeader from './layouts/NoHeader.vue'
import None from './layouts/None.vue'
import router from "./router";

import reqid from "./utils/reqid";

import './scss/app.scss';




Vue.use(VueClipboard)

Vue.use(Antd);
Vue.use(VueCookies);

Vue.config.productionTip = false;

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-noheader", NoHeader);
Vue.component("layout-none", None);
Vue.component('v-icon', Icon)


if (document.location.port == 8080) {
  Vue.prototype.BaseURL = document.location.origin.replace("8080", "8888");
} else {
  Vue.prototype.BaseURL = document.location.origin;
}


Vue.prototype.CDVersion = "1.0.0";
Vue.prototype.CDVersionTime = "2024-01-01";
Vue.prototype.Dayjs = dayjs;
Vue.http = Vue.prototype.$http = axios;

axios.defaults.baseURL = Vue.prototype.BaseURL;
axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.headers.post["Cache-Control"] = "no-cache";

//Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    config.headers.common["Authorization"] = "Bearer " + VueCookies.get("token");
    config.headers.common["account"] = VueCookies.get("account");
    config.headers.common["user_id"] = VueCookies.get("user_id");
    config.headers.common["requestId"] = reqid.GetRequestId();
    config.headers.common["timestamp"] = new Date().getTime();

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//Add response interceptor
axios.interceptors.response.use(
  function (response) {
    if (response.data.code == 1002) {
      // token Fail
      $cookies.remove("token");
      $cookies.remove("nick_name");
      $cookies.remove("account");
      $cookies.remove("user_id");

      window.location.href = "/";
    } else if (response.data.code == 9002) {
      router.push({ name: "err403" });
    }
    return response.data;
  },
  function (error) {
    message.destroy();
    message.error(error + "");
    // return Promise.reject(error);
    $cookies.remove("token");
    $cookies.remove("nick_name");
    $cookies.remove("account");
    $cookies.remove("user_id");
    window.location.href = "/login";
  }
);

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    var isToken = $cookies.isKey("token");

    if (isToken) {
      if (to.name == "403") {
        next();
      } else {
        try {
          var user_nav = VueCookies.get("user_nav").split(",");
          if ((user_nav.indexOf(to.name) > -1) || (to.name === "ProfileHome")) {
            next();
          } else {
            next({ path: "/403" });
          }
        } catch (error) {
          next();
        }
      }
    } else {
      next({ path: "/" });
    }
  } else {
    var isToken = $cookies.isKey("token");
    if (isToken) {
      if (to.name == "Login") {
        next({
          path: "/dashboard"
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')