import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () =>
    import('@/pages/login')
const Dashboard = () =>
    import('@/pages/dashboard')
const WorkflowHome = () =>
    import('@/pages/workflow/home')
const WorkflowEdit = () =>
    import('@/pages/workflow/edit')
const StatisticsHome = () =>
    import('@/pages/workflow/statistics')
const LogsHome = () =>
    import('@/pages/logs/home')
const AppHome = () =>
    import('@/pages/app/home')
const VariablenHome = () =>
    import('@/pages/variable/home')
const UserHome = () =>
    import('@/pages/user/home')
const ProfileHome = () =>
    import('@/pages/profile/home')
const SystemHome = () =>
    import('@/pages/system/home')
const TimerHome = () =>
    import('@/pages/timer/home')
const x403 = () =>
    import('@/pages/403')


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);
let routes = [
    {
    path: "/",
    name: "Login",
    component: Login,
    layout: "none",
    meta: { requireAuth: false }
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        layout: "dashboard",
        component: Dashboard,
        meta: { requireAuth: true }
    },
    {
        path: "/workflow",
        name: "WorkflowHome",
        layout: "dashboard",
        component: WorkflowHome,
        meta: { requireAuth: true }
    },
    {
        path: "/workflow/edit/:uuid",
        name: "WorkflowEdit",
        layout: "noheader",
        component: WorkflowEdit,
        meta: { requireAuth: true }
    },
    {
        path: "/workflow/statistics/:uuid",
        name: "StatisticsHome",
        layout: "dashboard",
        component: StatisticsHome,
        meta: { requireAuth: true }
    },
    {
        path: "/logs",
        name: "LogsHome",
        layout: "dashboard",
        component: LogsHome,
        meta: { requireAuth: true }
    },
    {
        path: "/app",
        name: "AppHome",
        layout: "dashboard",
        component: AppHome,
        meta: { requireAuth: true }
    },
    {
        path: "/variablen",
        name: "VariablenHome",
        layout: "dashboard",
        component: VariablenHome,
        meta: { requireAuth: true }
    },
    {
        path: "/user",
        name: "UserHome",
        layout: "dashboard",
        component: UserHome,
        meta: { requireAuth: true }
    },
    {
        path: "/profile",
        name: "ProfileHome",
        layout: "dashboard",
        component: ProfileHome,
        meta: { requireAuth: true }
    },
    {
        path: "/system",
        name: "SystemHome",
        layout: "dashboard",
        component: SystemHome,
        meta: { requireAuth: true }
    },
    {
        path: "/timer",
        name: "TimerHome",
        layout: "dashboard",
        component: TimerHome,
        meta: { requireAuth: true }
    },
    {
        path: "/403",
        name: "403",
        component: x403,
        meta: { requireAuth: true }
    }
]

function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;


const router = new VueRouter({
	routes: routes,
})

export default router
