<template>
	
	<!-- Main Sidebar -->
	<a-layout-sider
		collapsible
		class="sider-primary"
		breakpoint="lg"
		collapsed-width="0"
		width="250px"
		:collapsed="sidebarCollapsed"
		@collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
		:trigger="null"
		:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
		:theme="sidebarTheme"
		:style="{ backgroundColor: 'transparent',}">
			<div class="brand"><img width="32px" src="images/logo.png" alt=""> <span>Auto IT</span></div>
			<hr>
           
			<!-- Sidebar Navigation Menu -->

			<a-menu theme="light" mode="inline" >
				<a-menu-item v-for="item in user_nav" :key="item.key" v-show="item.is_menu===1">
					<!-- <a-icon :type="item.icon" /> -->
					<router-link :to="item.path" >
						<span class="icon">
								<v-icon :name="item.icon"></v-icon>
						</span>
						<span class="label">{{item.name}}</span>
					</router-link>
				</a-menu-item>
			</a-menu>
			<!-- / Sidebar Navigation Menu -->

			<!-- Sidebar Footer -->
			<div class="aside-footer">
				<div class="footer-box">
					<span class="icon">
						<v-icon name="life-ring" ></v-icon>
					</span>
					<h6>Need Help?</h6>
					<p>Please check our docs</p>
					<a-button type="primary" href="https://autoit.app/help" block target="_blank">
						DOCUMENTATION
					</a-button>
				</div>
			</div>
            <!-- Toggle dark mode  -->
             <!-- <div class="mode-toggle" @click="modeToggle" :class="darkDark">
                <div class="toggle">
                    <div id="dark-mode" type="checkbox"></div>
                </div>
            </div> -->
			<!-- / Sidebar Footer -->
	</a-layout-sider>
	<!-- / Main Sidebar -->

</template>

<script>
	import 'vue-awesome/icons'
	export default ({
		props: {
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},
		},
		data() {
        return {
            darkMode: false,
            baseURL: this.BaseURL,
            defaultSelectedKeys: ["Dashboard"],
            selectedKeys: [],
            theme: "dark",
            user_nav: [],
            nav_auth_list: ""
        };
    },
    computed: {
        darkDark() {
            return this.darkMode && 'darkmode-toggled'
        },
        collapsed: {
            get: function () {
                return this.$store.getters.getCollapsed;
            },
            set: function (val) {
                if (val) {
                    this.$store.commit("closeCollapsed");
                } else {
                    this.$store.commit("openCollapsed");
                }
            },
        },
    },
    mounted() {
        this.getTheme();
        this.setSelectedKeys();
        this.onGetUserNavList();
    },
    methods: {
        dark() {
            document.querySelector('body').classList.add('dark')
            this.darkMode = true
            this.$emit('dark')
            var theme = this.$cookies.get("theme");
            document.getElementById("app").className = theme;
            this.curr_theme = "dark";
            this.$store.commit("setTheme", this.curr_theme);
            this.sidebarTheme = "dark";
            this.sidebarColor = "black"
            document.querySelectorAll(".ant-layout")[1].style = "background: #202020;";
        },

        light() {
            document.querySelector('body').classList.remove('dark')
            this.darkMode = false
            document.getElementById("app").className = "bright";
            this.$emit('bright')
            this.curr_theme = "bright";
            this.$store.commit("setTheme", this.curr_theme);
            this.sidebarTheme = "light";
            this.sidebarColor = "primary"
            document.querySelectorAll(".ant-layout")[1].style = "background: #ffffff;";
        },

        modeToggle() {
            if(this.darkMode || document.querySelector('body').classList.contains('dark-mode')) {
                this.light()
            } else {
                this.dark()
            }
        },
        onGetUserNavList() {
            this.$http
                .post("/api/v1/rest/get/navigation/list", {
                    user_id: this.$cookies.get("user_id"),
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.user_nav = res.data;

                        this.user_nav.forEach(nav => {
                            this.nav_auth_list += "," + nav.key;
                        });

                        this.$cookies.set("user_nav", this.nav_auth_list);
                    }
                });
        },
        getTheme() {
            var is_theme = this.$cookies.isKey("theme");
            if (is_theme) {
                var theme = this.$cookies.get("theme");
                document.getElementById("app").className = theme;
                this.curr_theme = theme;
                this.$store.commit("setTheme", this.curr_theme);
            } else {
                document.getElementById("app").className = "bright";
                this.curr_theme = "bright";
                this.$store.commit("setTheme", this.curr_theme);
            }
        },
        setSelectedKeys() {
            setTimeout(() => {
                let router_key = this.$router.history.current.name;

                if (router_key == null) {
                    this.selectedKeys = ["Dashboard"];
                    return false;
                }

         
                var theme = this.$cookies.get("theme");

                if (theme === "dark") {
                    document.querySelectorAll(".ant-layout")[1].style = "background: #202020;";
                } else {
                    document.querySelectorAll(".ant-layout")[1].style = "background: #ffffff;";
                }
        

                if (router_key === "WorkflowEdit") {
                    this.selectedKeys = ["WorkflowHome"];
                    return false;
                } else {
                    this.selectedKeys = [router_key];
                    return false;
                }
            }, 1000);
        },
        collapsedClick() {
            if (this.collapsed) {
                this.collapsed = false;
            } else {
                this.collapsed = true;
            }
        }
    },
    watch: {
        '$store.getters.getTheme': function (e) {
            if (e === "dark") {
                this.theme = "dark";
            } else {
                this.theme = "bright";
            }
        }
    }
	})

</script>


<style lang="scss">
$dark: #171717;
$mode-toggle-bg: #262626;
.mode-toggle {
    position: relative;
    //margin: auto .5rem .5rem auto;
    padding: 0;
    width: 44px;
    height: 24px;
    min-width: 36px;
    min-height: 20px;
    background-color: $mode-toggle-bg;
    border: 0;
    border-radius: 24px;
    outline: 0;
    overflow: hidden;
    cursor: pointer;
    z-index: 2;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    appearance: none;
    transition: background-color .5s ease;

    .toggle {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        box-shadow: inset 0 0 0 2px #a5abba;
        overflow: hidden;
        transition: transform .5s ease;

        #dark {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 50%;

            &:before {
                content: '';
                position: relative;
                width: 100%;
                height: 100%;
                left: 50%;
                float: left;
                background-color: #a5abba;
                transition: border-radius .5s ease, width .5s ease, height .5s ease, left .5s ease, transform .5s ease;
            }
        }
    }
}

body.dark {
    .mode-toggle {
        background-color: lighten($mode-toggle-bg, 5%);

        .toggle {
            transform: translateX(19px);

            #dark {
                &:before {
                    border-radius: 50%;
                    width: 150%;
                    height: 85%;
                    left: 40%;
                    transform: translate(-10%, -40%), rotate(-35deg);
                }
            }
        }
    }
}
</style>