<!-- 
	This is the dashboard layout, used in dashboard, tables, billing and profile pages.
 -->

<template>
	<div>

			<!-- Layout Content -->
			<!-- <a-layout> -->

				<!-- Page Content -->
				<!-- <a-layout-content> -->
					<router-view />
				<!-- </a-layout-content> -->
				<!-- / Page Content -->

			<!-- </a-layout> -->
			<!-- / Layout Content -->
			

	</div>
</template>

<script>


	export default ({
		components: {
		},
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {
			
		},
	})

</script>
