<template>

	<!-- Layout Header ( Navbar ) -->

	<!-- / Layout Header ( Navbar ) -->

</template>

<script>

	export default ({
		data() {
			return {
				// Collapse navigation value.
				// Binded model property for "Collapsible Navigation Menu" collapsed status .
				// collapseNav: 0,
			}
		},
	})

</script>

<style lang="scss" scoped>


</style>